import { render, staticRenderFns } from "./ForwardList.vue?vue&type=template&id=7ee03c22&"
import script from "./ForwardList.vue?vue&type=script&lang=js&"
export * from "./ForwardList.vue?vue&type=script&lang=js&"
import style0 from "./ForwardList.vue?vue&type=style&index=0&id=7ee03c22&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports